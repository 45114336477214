export const ENTITY_TYPES: any = {
  ACTIVITY_INFO: "Informative activity",
  ACTIVITY_REQUEST: "Request activity",
  ACTIVITY_RESERVATION: "Reservation activity",
  ACTIVITY_PRODUCT_LISTING: "Product Listing activity",
  ACTIVITY_MENU: "Menu activity",
  CATEGORY: "Category",
  CATEGORY_ACCESS: "Category Access",
  HUB: "Hub",
  MENU_REQUEST: "Menu request",
  REQUEST: "Request",
  RESERVATION: "Reservation",
  SHOPPING: "Shopping",
  REVIEW: "Review"
};

export const ENTITY_TYPES_ENUM: any = {
  ACTIVITY_INFO: "ACTIVITY_INFO",
  ACTIVITY_REQUEST: "ACTIVITY_REQUEST",
  ACTIVITY_RESERVATION: "ACTIVITY_RESERVATION",
  ACTIVITY_PRODUCT_LISTING: "ACTIVITY_PRODUCT_LISTING",
  ACTIVITY_MENU: "ACTIVITY_MENU",
  CATEGORY: "CATEGORY",
  CATEGORY_ACCESS: "CATEGORY_ACCESS",
  HUB: "HUB",
  MENU_REQUEST: "MENU_REQUEST",
  REQUEST: "REQUEST",
  RESERVATION: "RESERVATION",
  SHOPPING: "SHOPPING",
  REVIEW: "REVIEW"
};

export const ABUSE_REPORT_STATUS: any = {
  APPROVED: "Approved",
  CANCELED: "Canceled",
  OPEN: "Open",
  REJECTED: "Rejected",
  RESOLVED: "Resolved",
};

export const HUB_REPORT_STATUS: any = {
  APPROVED: "Approved",
  CANCELED: "Canceled",
  OPEN: "Open",
  PENDING: "Pending",
  REJECTED: "Rejected",
  RESOLVED: "Resolved",
  GRANTED: "Granted"
};

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getWeekDaysSorted = (initialDay: number) => {
  const sortedDays = [];
  let loops = DAYS.length;
  let index = initialDay;
  while (loops > 0) {
    sortedDays.push(DAYS[index]);
    index += 1;
    if(index === DAYS.length) {
      index = 0;
    }
    loops -= 1;
  }
  return sortedDays;
}

export const AccountStatus:{[key: string]: string} = {
  FREE: 'FREE', 
  PREMIUM:'PREMIUM',
  ROYAL:'ROYAL', 
  EXPIRED: 'EXPIRED'
};

export const SubscriptionProvider:{[key: string]: string} = {
  LOCAL: 'LOCAL', 
  STRIPE: 'STRIPE', 
};

export const SubscriptionOptions:{[key: string]: string} = {
  ONE_MONTH: '1 Month',
  TWO_MONTHS: '2 Months',
  THREE_MONTHS: '3 Months',
  SIX_MONTHS: '6 Months',
  TWELVE_MONTHS: '12 Months',
};

export const IntervalSubscriptionOptions:{[key: string]: string} = {
  month: '1 Month',
  bimestral: '6 Months',
  year: '12 Months',
};



